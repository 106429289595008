import { Autocomplete, Box, FormHelperText, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { Option } from './TextField';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function AutomcompleteField({
  name,
  options,
  isLoading,
  label,
  required,
  placeholder,
  onInputValueChange,
}: {
  readonly name: string;
  readonly options: Option[];
  readonly isLoading?: boolean;
  readonly label?: string;
  readonly required?: boolean;
  readonly placeholder?: string;
  readonly onInputValueChange: (value: string) => void;
}) {
  const formContext = useFormContext();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');
  const error = formContext.formState.errors[name];
  let hasError = !!error?.message;
  let errorMsg = String(error?.message);

  if (error?.type === 'required' && !hasError) {
    hasError = true;
    errorMsg = t('field_required');
  }

  return (
    <Box>
      {label ? (
        <label
          style={{
            fontSize: '.875rem',
            fontWeight: 400,
          }}
          htmlFor="site_template_sites_autocomplete"
        >
          {label}
        </label>
      ) : null}
      <Autocomplete
        onChange={(_event: any, newValue: Option | null) => {
          const value = newValue?.value;
          if (value) {
            formContext.setValue(name, Number(value));
            required && formContext.clearErrors(name);
          } else {
            formContext.setValue(name, -1);
            required && formContext.setError(name, { type: 'required' });
          }
        }}
        inputValue={inputValue}
        onInputChange={(_event, newInputValue) => {
          onInputValueChange(newInputValue);
          setInputValue(newInputValue);
        }}
        id="site_template_sites_autocomplete"
        options={options}
        disabled={isLoading}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            placeholder={placeholder}
            size="small"
            error={Boolean(formContext.formState.errors[name])}
            onBlur={() => {
              if (!formContext.watch(name)) {
                required && formContext.setError(name, { type: 'required' });
              }
            }}
          />
        )}
      />
      {hasError ? <FormHelperText error>{errorMsg}</FormHelperText> : null}
    </Box>
  );
}
