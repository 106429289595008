import { Box, Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SiteTemplatesList } from './SiteTemplatesList';
import AddSiteTemplate from './AddSiteTemplate';

export default function SiteTemplates() {
  const { t } = useTranslation();

  return (
    <main id="mainContent">
      <Container maxWidth="lg">
        <Stack direction="row" justifyContent="space-between">
          <Box marginBottom={3}>
            <Typography variant="h1" data-testid="domainLabel">
              {t('site_templates')}
            </Typography>
          </Box>
          <AddSiteTemplate />
        </Stack>
        <SiteTemplatesList />
      </Container>
    </main>
  );
}
